
.cell-header-custom{
    outline: 1px solid #1e5d9d;
    border-top: 2px solid currentColor !important;
    border-bottom: 2px solid currentColor !important;
    color: #fff;
    background-color: #1e5d9d;    
}
.table-headers{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-size: x-large;
    font-weight: bolder;
    color:#1e5d9d;
}
.table {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 2rem 0rem 0rem 0rem;
    max-width: 1100px;
}
 .table-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style-type: none;
    outline: 1px solid #ddd;
    text-align: center;
    font-weight: 300;
    margin-top: 1px;
    margin-left: 1px;
}
 
 @media only screen and (max-width: 768px) {
    .table-cell:first-child {
       display: none;
   }
   .chart-section{
    display: block !important;
   }
}  
 .table-cell > h3 {
    font-size: 26px;
    margin-top: 0;
}
 .table-cell.cell-feature {
    text-align: left;
    font-size: 15px;
}
 .table-cell.plattform {
    color: limegreen;
}
 .table-cell.plattform a.btn {
    color: limegreen;
}
 .table-cell.plattform a.btn:hover {
    background-color: limegreen;
    border-color: limegreen;
    color: white;
}
 .table-cell.enterprise {
    background-color: #30305b;
    color: #85bafc;
}
 .table-cell.enterprise a.btn {
    color: #85bafc;
}
 .table-cell.enterprise a.btn:hover {
    background-color: #85bafc;
    border-color: #85bafc;
    color: #30305b;
}
/* Table columns ================================== */
 .table-cell {
    width: calc(33.33% - 1px);
}
 @media only screen and (max-width: 768px) {
    .table-cell.cell-feature {
       /* width: 100%; */
       text-align: center;
   }
}
 svg.enterprise-check path {
    fill: #30305b !important;
}
 a.btn {
    border: 2px solid;
    padding: .6rem .9rem .6rem .9rem;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    border-radius: 0.3rem;
}
 
.cell-align-center{
    display: flex;
    justify-content: center;
}

.apexcharts-datalabel-value{
    display: flex;
}
.header-section{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    color: #15497e;
    font-weight: 700;
}
.chart-section{
    animation: slidein 200ms ease-in-out 0s 1 normal both;
}
@keyframes slidein {
    0% {
      transform: scaleY(0.4);transform-origin: 100% 0%;
    }
    100% {
      transform: scaleY(1);transform-origin: 100% 0%;
    }
    
  }
  .chart-icon{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .ccb-no-data{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;    
    /* top: -20px; */
    position: relative;
  }

  @media (min-width: 700px){
  .ccb-first{
    padding-left: 50px;
    color:#324A6D;
  }
  }
  .ccb-first{
    color:#324A6D;
  }
  .ccb-no-data-header{    
    /* font-weight: bolder; */
    font-size: 13.0pt;    
  }
  .ccb-no-data-header-others{    
    /* font-weight: bolder; */
    font-size: 13.0pt;   
  }

  .tables table{
    margin-top: 20px;
  }
.tables {
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    color: #212529; }
    .tables th,
    .tables td {
      padding: 0.35rem;
      vertical-align: top;      
      border-top: 1px solid #dee2e6; }
    .tables thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }
    .tables tbody + tbody {
      border-top: 2px solid #dee2e6; }
  .tables thead th{
    background-color: #15497e;
    color:#fff;
  }
  .table-sm th,
  .table-sm td {
     padding: 0.3rem;  
  }
  
  .table-bordered {
    border: 1px solid #dee2e6; }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #dee2e6; }
    .table-bordered thead th,
    .table-bordered thead td {
      border-bottom-width: 2px; }
  
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0; }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075); }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #b8daff; }
  
  .table-primary th,
  .table-primary td,
  .table-primary thead th,
  .table-primary tbody + tbody {
    border-color: #7abaff; }
  
  .table-hover .table-primary:hover {
    background-color: #9fcdff; }
    .table-hover .table-primary:hover > td,
    .table-hover .table-primary:hover > th {
      background-color: #9fcdff; }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #d6d8db; }
  
  .table-secondary th,
  .table-secondary td,
  .table-secondary thead th,
  .table-secondary tbody + tbody {
    border-color: #b3b7bb; }
  
  .table-hover .table-secondary:hover {
    background-color: #c8cbcf; }
    .table-hover .table-secondary:hover > td,
    .table-hover .table-secondary:hover > th {
      background-color: #c8cbcf; }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb; }
  
  .table-success th,
  .table-success td,
  .table-success thead th,
  .table-success tbody + tbody {
    border-color: #8fd19e; }
  
  .table-hover .table-success:hover {
    background-color: #b1dfbb; }
    .table-hover .table-success:hover > td,
    .table-hover .table-success:hover > th {
      background-color: #b1dfbb; }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb; }
  
  .table-info th,
  .table-info td,
  .table-info thead th,
  .table-info tbody + tbody {
    border-color: #86cfda; }
  
  .table-hover .table-info:hover {
    background-color: #abdde5; }
    .table-hover .table-info:hover > td,
    .table-hover .table-info:hover > th {
      background-color: #abdde5; }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba; }
  
  .table-warning th,
  .table-warning td,
  .table-warning thead th,
  .table-warning tbody + tbody {
    border-color: #ffdf7e; }
  
  .table-hover .table-warning:hover {
    background-color: #ffe8a1; }
    .table-hover .table-warning:hover > td,
    .table-hover .table-warning:hover > th {
      background-color: #ffe8a1; }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f5c6cb; }
  
  .table-danger th,
  .table-danger td,
  .table-danger thead th,
  .table-danger tbody + tbody {
    border-color: #ed969e; }
  
  .table-hover .table-danger:hover {
    background-color: #f1b0b7; }
    .table-hover .table-danger:hover > td,
    .table-hover .table-danger:hover > th {
      background-color: #f1b0b7; }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fdfdfe; }
  
  .table-light th,
  .table-light td,
  .table-light thead th,
  .table-light tbody + tbody {
    border-color: #fbfcfc; }
  
  .table-hover .table-light:hover {
    background-color: #ececf6; }
    .table-hover .table-light:hover > td,
    .table-hover .table-light:hover > th {
      background-color: #ececf6; }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #c6c8ca; }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #95999c; }
  
  .table-hover .table-dark:hover {
    background-color: #b9bbbe; }
    .table-hover .table-dark:hover > td,
    .table-hover .table-dark:hover > th {
      background-color: #b9bbbe; }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
    .table-hover .table-active:hover > td,
    .table-hover .table-active:hover > th {
      background-color: rgba(0, 0, 0, 0.075); }
  
  .tables .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55; }
  
  .tables .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  
  .table-dark {
    color: #fff;
    background-color: #343a40; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th {
      border-color: #454d55; }
    .table-dark.table-bordered {
      border: 0; }
    .table-dark.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05); }
    .table-dark.table-hover tbody tr:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.075); }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-sm > .table-bordered {
        border: 0; } }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-md > .table-bordered {
        border: 0; } }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-lg > .table-bordered {
        border: 0; } }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .table-responsive-xl > .table-bordered {
        border: 0; } }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive > .table-bordered {
      border: 0; }
      .tables tr td.numbers{
text-align: right;
border-right: 1px inset;
      }
      .tables tr td.string{
        text-align: left;
        border-right: 1px inset;
      }
      .tables table{
        width:75%;
      }

.no-data-img img{
height: 300px !important;
}

@media only screen and (max-width: 768px) {
  .no-data-img{
    display: none !important;    
  }
}

.no-data-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.elementor-widget-container{
  color:#15497e
}

@media (min-width:700px){
.no-data-page-center{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #15497e;
  padding-left: 43px;
}
}

.no-data-page-center{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #15497e;
}

.no-data-page-center span{padding-top: 20px;
  font-size: 1.9rem;
  color:#6889ac !important;
}

.ccl-edition-header>div>span{
  font-weight: bold;
    
    font-size: larger;
}
.ccl-edition-header{
  padding-left: 10px;
  color:#15497e;
  display: flex;
  justify-content: space-between;
}
.ccl-tables{
  justify-content: flex-start !important;
  padding-left: 10px;
}
.ccc-workloads input{
  height: 20px;
  width:100%;
}
.numbers{
  width:134px;
  text-align: right !important;
}
.numbers input{
  text-align: right !important;
}