
.options {
   /* // border: 1px solid #e5e5e5; */
    padding: 1px;
    width: 100%;
    /* background-color: white; */
  }
  
  select {
    font-size: 14px;
    border: none;
    width: 100%;
    background: white;
  }
  
#react-select-2-listbox{
    z-index: 1000;
}