.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.flex-item {
  width: 300x;
  height: 300px;
  padding-left: 10px;
  margin: 5px;
  border: 1px solid #15497e;
  box-shadow: 4px 3px 8px 1px #969696;
  -webkit-box-shadow: 4px 3px 8px 1px #969696;
  border-radius: 10%;
}

.flex-item div {
  color: azure;
}


.grid-container {
  /* padding-left: 50px;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(4, 1fr); */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  gap: 3pc;
  row-gap: 50px;
}

@media screen and (min-width: 2200px) {
  .grid-item {
    width: 380px !important;
    height: 240px !important;
  }
}


.grid-item {
  padding-top: 8px;
  width: 320px;
  height: 240px;
  margin: 5px;
  border: 1px solid #15497e;
  box-shadow: 4px 3px 8px 1px #969696;
  -webkit-box-shadow: 4px 3px 8px 1px #969696;
  border-radius: 10%;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  align-items: center;
}

.grid-item .chart-header {
  font-weight: bold;
  font-size: x-large;
  color: #15497e;
  width: 100%;
  text-align: center;
}

.loader-chart {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
}

.old-activity {
  padding-top: 15px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  color: grey !important;
}

.old-activity div {
  padding-bottom: 10px;
  color: grey !important;
}

.old-activity svg {
  color: grey !important;
}

.old-activity i,
svg:hover {
  color: grey !important;
}

.old-activity span {
  font-size: 20px;
}

#manage-no-data {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}