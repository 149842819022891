body {
  background-color: white;
  height: 99%;
  background-size: cover;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.fa-2x {
  font-size: 2em;
}

.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}


.main-menu:hover,
nav.main-menu.expanded {
  width: 260px;
  overflow: visible;
}

.main-menu {
  background: #fff;
  border-right: 1px solid #e5e5e5;
  position: absolute;
  top: 50px;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width .05s linear;
  transition: width .05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.main-menu>ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 260px;
}

.main-menu li>a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all .1s linear;
  transition: all .1s linear;

}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  font-family: 'Titillium Web', sans-serif;
  color: black;
}

.main-menu>ul.logout {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  left: 0;
  bottom: 36px;
  background-color: #1e5d9d;
  color: white;
  font-weight: bold;
}

.main-menu>ul.logout>li>span {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.main-menu>ul.logout>li>span>svg {
  color: white;
}

.main-menu>ul.logout>li>span>svg:hover {
  color: white !important;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 5px 0 5px 0;
}

.main-menu li>div:hover>a,
nav.main-menu li>div.active>a,
.dropdown-menu>li>div>a:hover,
.dropdown-menu>li>div>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li>div:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #1e5d9d;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

div.headerCC {
  margin-left: auto;
  /* margin-right: auto; */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
}

.fa-2x {
  position: relative;
  display: table-cell;
  width: 60px !important;
  height: 36px !important;
  padding: 5px 0;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

/* .layout {

  display: grid;
  grid:
    "sidebar body" 1fr
    / auto 1fr;
    "footer" auto / 1fr
  gap: 8px;
}

.sidebar { grid-area: sidebar; }
.body { grid-area: body; } */

.layout {
  display: grid;
  grid:
    "header" auto "main" 1fr  "footer" auto / 1fr;
  gap: 8px;
}

.header {
  grid-area: header;
  background-color: #1e5d9d;
  width: -webkit-fill-available;
}

.sidebar {
  grid-area: sidebar;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
}

.statusOverview {
  background-image: url('../images/icons/Asset\ 19.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Contract {
  background-image: url('../images/icons/Asset\ 35.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.quota {
  background-image: url('../images/icons/Asset\ 27.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Billing {
  background-image: url('../images/icons/Asset\ 20.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Partner {
  background-image: url('../images/icons/Asset\ 30.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Addcustomer {
  background-image: url('../images/icons/Asset\ 29.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Reports {
  background-image: url('../images/icons/Asset\ 28.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.partner-portal {
  background-image: url('../images/icons/Asset\ 40.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/*page icons*/
.company-info {
  background-image: url('../images/icons/Asset\ 19.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.company-users {
  background-image: url('../images/icons/Asset\ 27.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.comapany-alarms {
  background-image: url('../images/icons/Asset\ 26.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.comapany-ccl {
  background-image: url('../images/icons/Asset\ 25.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.comapany-ccb {
  background-image: url('../images/icons/Asset\ 24.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.comapany-ccr {
  background-image: url('../images/icons/Asset\ 23.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.comapany-cc365 {
  background-image: url('../images/icons/Asset\ 22.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.comapany-cck8s {
  background-image: url('../images/icons/Asset\ 21.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.adminFunctions{
  background-image: url('../images/icons/Asset\ 16.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.loading-cc365{
  background-image: url('../images/icons/Asset\ 39.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.header-profile {
  font-size: xx-large;
  font-weight: 700;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  color: white;
}

.headerToggleIcon {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.togglemenuIcon>svg {
  color: white;
  height: 40px;
  width: 40px !important;
  cursor: pointer;
}

.togglemenuIcon>svg:hover {
  color: white !important;
}

.logout-icon {
  color: white !important;
  font-weight: 600;
}

.logout-icon:hover {
  color: white !important;
  font-weight: 600;
}

.logout-span {
  color: white !important;
  font-weight: 600;
  font-size: larger;
}
.active>a .nav-text{
  color: white;
  font-weight: 700 !important;
}

.header-user-info{
  float: right;
  color:white;
  padding-right: 10px;
  font-weight: bolder;
  display: flex;
  flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}
.header-user-icon {
  height: 20px;
  width: 20px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: white;
  color: #1e5d9d;
}
.widget-heading{
  padding: 10px;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 280px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
}
.sidebar ul.logout{
  position: absolute;
  bottom: 35px;
}

.sidebar li {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
  cursor: pointer;
}
 
.sidebar li.active {
  background-color: #1e5d9d;
  color: white;
}

.sidebar li:hover:not(.active) {
  background-color: #1e5d9d;
  color: #fff;
}

div.contentpanes {
  margin-left: 275px;
  padding: 1px 16px;
  height: auto;
  overflow: auto;
}

@media screen and (max-width: 360px) {
  div.contentpanes {
    width: calc(75%)!important;
}
}

@media screen and (max-width: 700px) {
  .sidebar {
    /* width: 100%; */
    height: auto;
    position: relative;
  }
  .layout-grid  {
    display: flex !important;
    flex-wrap: wrap;
    overflow: hidden;
}
  .sidebar li {float: left;}
  div.contentpanes {margin-left: 0;}
  #sidebar-grid{
    width: 73px;
    overflow: overlay;
  }
  div.contentpanes {
    width: calc(80%);
}
}

@media screen and (max-width: 400px) {
  .sidebar li {
    text-align: center;
    float: none;
  }
}
/* @media screen and (max-height: 700px)
{
  .contentpanes{
    height:  600px !important;   
  }
}
@media screen and (max-height: 400px)
{
  .contentpanes{
    height: 325px !important;   
  }
} */

.menu-items{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.last {
  position: relative;
  width: 280px;
  background-color: #1e5d9d;
  color: #fff;
}

.submenu:hover{
  color: #fff !important;
}
.sidebar li:hover :not(.active) .submenu,li.hover .submenu {
  background-color: #1e5d9d;
  color: #fff;
}
.hidesubmenu{
  display: none !important;
  transition: all .3s ease-in-out;
  min-height: 0;
  height:0px;
}
.showsubmenu{
  display: block !important;
  transition: all .3s ease-in-out;
}
.text {
  background: green;
  color: #fff;
  transition: all .3s ease-in-out;
  min-height: 0;
  height:0px;
  overflow: auto;
}

.max {
  transition: all .3s ease-in-out;
}

@keyframes lineInserted {
  from {
    height: 0;
  }
  to {
    height: 1px; /* your line height here */
  }
}
li[contenteditable] > li {
  animation-duration: 0.3s;
  animation-name: lineInserted;
  transition: height 0.3s;
}
#main-ul{
  overflow-x: auto;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;  
}
.loader {
  position: fixed;
  margin: auto;
  top: 44%;
  left: 55%;
  border: 5px solid #ffffff;
  border-radius: 50%;
  border-top: 5px solid #3F51B5;
  width: 50px;
  height: 50px;
  animation: spinner 4s linear infinite;
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }

}