 .workload.ag-theme-alpine .ag-header-cell,
 .ag-theme-alpine .ag-header-group-cell {
   /* color: #dde2eb; */
   color:#fff;
   transform: rotate(180deg) !important;
   --ag-odd-row-background-color:#1b6d85 !important;
 }

 .workload .ag-theme-alpine .ag-header-cell-resize .ag-theme-alpine .ag-header-icon {
   display: none;
 }

 .ag-theme-alpine .ag-header-cell,
 .ag-theme-alpine .ag-header-group-cell {
   border-left: 1px solid #babfc7;
   font-weight: 500;
   background-color:#1e5d9d ;
 }

 .ag-header-row.ag-header-row-column-group {
   border-bottom: 1px solid #babfc7;
   border-right: 1px solid #babfc7;
 }

 .ag-theme-alpine .ag-cell {
   border-left: 1px solid #babfc7 !important;
 }

 .ag-header-group-text {
   color: #fff;
 }

 .workload .ag-header-group-cell-no-group {
   border: 0 !important;
 }

 .workload .ag-header-group-cell-no-group {
   border-right: 0 !important;
 }

 .workload .ag-theme-alpine .ag-header-cell,
 .ag-theme-alpine .ag-header-group-cell {
   transform: none !important;
 }

 .workload .ag-header-cell-resize {
   display: none !important;
 }

 .workload .ag-header-cell-label .ag-header-cell-text {
   /*Force the width corresponding at how much width
      we need once the text is laid out vertically*/
   /* width: 55px; */
   writing-mode: vertical-lr;
   -ms-writing-mode: tb-lr;
   line-height: 2em;
   margin-top: 10px;
 }

 .workload .ag-theme-alpine .ag-header-icon {
   display: none !important;
 }

 .workload .ag-header-group-cell-label,
 .ag-header-cell-label {
   display: flex;
   flex: 1 1 auto;
   overflow: hidden;
   text-overflow: ellipsis;
   align-self: stretch;
   justify-content: flex-start;
   align-content: space-between;
   flex-wrap: nowrap;
   align-items: center;
   flex-direction: column;
 }

 .ag-header-group-text .ag-header-cell-text {
   color: #000 !important;
 }

 .workload .ag-overlay-wrapper {
   padding-top: 50px;
   ;
 }

 .workload .ag-header-cell-label {
   /*Necessary to allow for text to grow vertically*/
   height: 100%;
   padding: 0 !important;
 }

 .workload .ag-header-cell-label .ag-header-cell-text {
   /*Force the width corresponding to how much width
        we need once the text is laid out vertically*/
   /* width: 55px; */
   writing-mode: vertical-lr;
   -ms-writing-mode: tb-lr;
   line-height: 2em;
   margin-top: 10px;
   color: #fff !important;
 }


 .workload .ag-pivot-off .ag-header-cell-label {}

 .workload .ag-pivot-on .ag-header-cell-label {
   font-size: 10px;
   height: 90px;
   padding-top: 36px;
   margin-left: 0px;
   color: #1b6d85;
   font-weight: bold;
 }

 .workload .ag-pivot-on .ag-header-cell-label .ag-header-cell-text {
   /*There is empty space left at the top from the menu and sort
        icons not used*/
   margin-top: 25px;
 }

 /*Floating filters*/
 .workload .ag-floating-filter-body {
   height: 50px;
 }

 .workload .ag-floating-filter-body input {
   height: 49px;
 }

 .workload .ag-floating-filter-button {
   margin-top: -49px;
 }

 .workload .ag-floating-filter-button button {
   height: 49px;
 }

 .workload .ag-floating-filter-body input {
   font-size: 15px;
   font-weight: bold;
 }

 .regular-header .ag-header-cell-text {
   -webkit-writing-mode: horizontal-tb;
   transform: rotate(180deg);
   writing-mode: horizontal-tb;
 }

 .regular-header .ag-header-cell-label .ag-header-cell-text {
   width: auto;
   writing-mode: horizontal-tb;
   -ms-writing-mode: tb-lr;
   line-height: 2em;
   margin-top: 10px;
   /* color: #000 !important; */
   color: #fff !important;
 }

 .ag-header-cell-menu-button {
   display: block;
   width: 0;
 }

 .ag-icon-desc {
   color: #fff !important;
 }

 .ag-icon-asc {
   color: #fff !important;
 }

 .workload .ag-floating-filter-full-body {
   transform: rotate(180deg);
 }

 .workload .regular-header .ag-header-cell-label {
   display: flex;
   flex-wrap: nowrap;
   flex-direction: row-reverse;
   align-items: baseline;
   align-content: space-around;
   justify-content: center;
 }
 .text-align-center{
  text-align: center;
 }
 .ag-header-group-cell-label, .ag-header-cell-label{
  flex-direction: row;
 }
 .ag-theme-alpine .ag-row-odd{
  background-color: rgb(30,90,137,0.2) !important;
 }