/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.overlayOff {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
.overlayOn {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

#text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.button_text{
  padding: 0 10px 0 10px;
}

.spinners{
color: #fff;
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #9ea1a5;
  border-color: #2c2f32;
}

.pl-content{
  padding-left: 250px;
}
.pl-default-content{
  padding-left: 50px;
}
.modal-header{
  background-color:#1e5d9d;
  color:#fff;
}

/* signin button text animation */

@-webkit-keyframes mask-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(129px);
  }
}
@keyframes mask-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(129px);
  }
}
@-webkit-keyframes mask-inner-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-129px);
  }
}
@keyframes mask-inner-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-129px);
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.focus {
  color: white;
  letter-spacing: 0.2rem;
  line-height: 1;
  position: relative;
  width: auto;
  overflow: hidden;
}
.focus:before {
  content: "Sign In";
  filter: blur(3px);
}
.focus:after {
  content: "";
  position: absolute;
  width: auto;
  height: calc(100% + 8px); 
  -webkit-animation: mask-move 1s linear infinite alternate;
          animation: mask-move 1s linear infinite alternate;
}
.focus--mask {
  overflow: hidden;
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  left: 0;
  background:inherit;
  -webkit-animation: mask-move 1s linear infinite alternate;
          animation: mask-move 1s linear infinite alternate;
}
.focus--mask-inner {
  -webkit-animation: mask-inner-move 1s linear infinite alternate;
          animation: mask-inner-move 1s linear infinite alternate;
}

.waviy {
  position: relative;
}
.waviy span {
  position: relative;
  display: inline-block;
  color: #fff;
  animation: flip 2s infinite;
  animation-delay: calc(.2s * var(--i))
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
  }
}

.tracking-out-expand {
	-webkit-animation: tracking-out-expand 0.8s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1s infinite both;
	        animation: tracking-out-expand 0.8s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1s infinite both;
}

@-webkit-keyframes tracking-out-expand {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    opacity: 0;
  }
}
@keyframes tracking-out-expand {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    opacity: 0;
  }
}

.tracking-out-expand-fwd-bottom {
	-webkit-animation: tracking-out-expand-fwd-bottom 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
	        animation: tracking-out-expand-fwd-bottom 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
}
@-webkit-keyframes tracking-out-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px) translateY(200px);
            transform: translateZ(300px) translateY(200px);
    opacity: 0;
  }
}
@keyframes tracking-out-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px) translateY(200px);
            transform: translateZ(300px) translateY(200px);
    opacity: 0;
  }
}

.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.underline {
  display: inline-block;
  position: relative;
}
    .underline:after {
        position: absolute;
        content: '';
        bottom: -2px;
        left: 0px;
        height: 2px;
        width: 0%;
        background-color: #4d92d2;
        animation: underline 3s ease-in-out 0s infinite; /* remove infinite if you want only once */
    }
@keyframes underline {
  to {
    width: 100%;
  }
}

/* ends */