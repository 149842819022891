.tab-pane.fade {
  transition: all 0.2s;
  transform: translateY(1rem);
}

.box.arrow-right:after {
  content: " ";
  position: absolute;
  right: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid black;
  border-bottom: 15px solid transparent;
}

.tab-pane.fade.show {
  transform: translateY(0rem);
}

.tab-pane1.active {
  -webkit-animation: appear 1s ease 0s 1 normal;
  -moz-animation: appear 1s ease 0s 1 normal;
  -ms-animation: appear 1s ease 0s 1 normal;
  animation: appear 1s ease 0s 1 normal;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  60% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}




@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.tab-pane.active {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* animation ends */

/* form designs start */
.form-style-5 {
  padding: 10px 20px;
  background: #f4f7f8;
  margin: 10px auto;
  padding: 20px;
  background: #f4f7f8;
  border-radius: 8px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.form-style-5 h1 {
  background: #007bff;
  padding: 20px 0;
  font-size: 140%;
  font-weight: 300;
  text-align: center;
  color: #fff;
  margin: -16px -16px 16px -16px;
}

.form-style-5 fieldset {
  border: none;
}

.form-style-5 legend {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  background-color: #007bff;
}

.form-style-5 label {
  display: block;
  margin-bottom: 8px;
}

.form-style-5 input[type="text"],
.form-style-5 input[type="date"],
.form-style-5 input[type="datetime"],
.form-style-5 input[type="email"],
.form-style-5 input[type="number"],
.form-style-5 input[type="search"],
.form-style-5 input[type="time"],
.form-style-5 input[type="url"],
.form-style-5 textarea,
.form-style-5 select {
  font-family: Georgia, "Times New Roman", Times, serif;
  background: rgba(255, 255, 255, .1);
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #e8eeef;
  color: #8a97a0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  margin-bottom: 30px;
}

.form-style-5 input[type="text"]:focus,
.form-style-5 input[type="date"]:focus,
.form-style-5 input[type="datetime"]:focus,
.form-style-5 input[type="email"]:focus,
.form-style-5 input[type="number"]:focus,
.form-style-5 input[type="search"]:focus,
.form-style-5 input[type="time"]:focus,
.form-style-5 input[type="url"]:focus,
.form-style-5 textarea:focus,
.form-style-5 select:focus {
  background: #d2d9dd;
}

.form-style-5 select {
  -webkit-appearance: menulist-button;
  height: 35px;
}

.form-style-5 .number {
  background: #1abc9c;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 0px;
}

/* .form-style-5 input[type="submit"],
.form-style-5 input[type="button"] {
  position: relative;
  display: block;
  padding: 19px 39px 18px 39px;
  color: #FFF;
  margin: 0 auto;
  background: #1abc9c;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  width: 100%;
  border: 1px solid #16a085;
  border-width: 1px 1px 3px;
  margin-bottom: 10px;
} */

/* .form-style-5 input[type="submit"]:hover,
.form-style-5 input[type="button"]:hover {
  background: #109177;
} */

/* form designs end */

/* custom checkbox */
.form-style-5 input[type=checkbox] {
  visibility: hidden;
}

.checkbox-custom {
  width: 45px;
  height: 15px;
  background: #555;
  margin: 20px 10px;
  position: relative;
  border-radius: 5px;
}

.checkbox-custom label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all .5s ease;
  cursor: pointer;
  position: absolute;
  top: -2px;
  left: -3px;
  background: #ccc;
}

.checkbox-custom input[type=checkbox]:checked+label {
  left: 27px;
}

/* ends */

.rigthsection {
  height: 88%;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  width: 75%;
}

.close-btn {
  font-size: larger;
}

.modal__header {
  padding-top: 8px;
}

.row-form {
  padding: 0.5rem;
}

.row-form input {
  width: 80%;
}

@media only screen and (max-width: 768px) {
  .action-button {
    padding-bottom: 20px;
  }
}

.action-button {
  display: flex;
  justify-content: flex-end;
}
.btn-form-style{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.btn-outline-primary{
  color: #0d6efd !important;
    border-color: #0d6efd !important;
    background-color:#fff;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}
.btn-outline-secondary {
  color: #6c757d !important;
  border-color: #6c757d !important;
  background-color:#fff;
}
.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-disable>div>svg{
  color:grey;
}


.users-form{
  /* background-color: #dadbdd; */
  margin-left: 50px;
  border-radius: 2%;
  border: 1px solid #15497e;
  width: 80%, import !important;
  -webkit-box-shadow: 4px 3px 8px 1px #969696;
  box-shadow: 4px 3px 8px 1px #969696;
}
.users-form span{
  font-size: large;
  font-weight: 700;
}

.user-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .users-form {
    margin-left: 0 !important;
  }
}

.input-select-cloud{
  width: 80%;
  border: 1px solid;
}