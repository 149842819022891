.ph-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 15px 15px 15px;
    margin-bottom: 30px;
    overflow: hidden;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
  }
  .ph-item,
  .ph-item *,
  .ph-item ::after,
  .ph-item ::before {
    box-sizing: border-box;
  }
  .ph-item::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    pointer-events: none;
    content: " ";
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
    -webkit-animation: ph-animation 0.8s linear infinite;
            animation: ph-animation 0.8s linear infinite;
  }
  .ph-item > * {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 15px;
  }
  
  .ph-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -7.5px;
  }
  .ph-row div {
    height: 10px;
    margin-top: 7.5px;
    background-color: #ced4da;
  }
  .ph-row .big, .ph-row.big div {
    height: 20px;
  }
  .ph-row .empty {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .ph-col-2 {
    flex: 0 0 16.6666666667%;
  }
  
  .ph-col-4 {
    flex: 0 0 33.3333333333%;
  }
  
  .ph-col-6 {
    flex: 0 0 50%;
  }
  
  .ph-col-8 {
    flex: 0 0 66.6666666667%;
  }
  
  .ph-col-10 {
    flex: 0 0 83.3333333333%;
  }
  
  .ph-col-12 {
    flex: 0 0 100%;
  }
  
  [class*=ph-col] {
    direction: ltr;
  }
  [class*=ph-col] > * + .ph-row {
    margin-top: 0;
  }
  [class*=ph-col] > * + * {
    margin-top: 7.5px;
  }
  
  .ph-avatar {
    position: relative;
    width: 50%;
    min-width: 30px;
    overflow: hidden;
    background-color: #ced4da;
    border-radius: 50%;
  }
  .ph-avatar::before {
    display: block;
    padding-top: 100%;
    content: " ";
  }
  
  .ph-picture {
    width: 100%;
    height: 120px;
    background-color: #ced4da;
  }
  
  @-webkit-keyframes ph-animation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }
  
  @keyframes ph-animation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }


  /*spinner attribute*/
  .lds-company {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-company div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #6590bb;
    animation: lds-company 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-company div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-company div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-company div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-company {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  