p,
li {
    font-family: 'Roboto', sans-serif;
    font-size: medium;
}

/* Menu Options*/

body{
overflow-x: hidden;
}

.container {
    max-height: 100%;
    overflow: hidden;
}

.menulogo {
     background: url(../images/vdcsmenulogo.png) no-repeat;
    width: 227px;
    height: 30px;
    opacity: 0.9;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
}

.custStyle {
    background-color: #fefefe;
    padding: 2px 0;
}

.MenuFont {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
}

/* Footer Options*/

.footer {
    position: fixed;
    bottom: 1px;
    background: none;
    background-color: none;
    height: 70px;
}

.modal-content {
    width: fit-content;
    left: 25%;
}

/* Section Titles*/

.SectionTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 21px;
}


/* Help Titles*/

.titletext {
    font-family: 'Roboto', sans-serif;
    font-size: 23px;
    padding-top: 9px;
    vertical-align: text-bottom;
}

/* Wizards*/


.wizardtitles {
    font-family: 'Roboto', sans-serif;
    font-size: large;
}

.wizardtext {
    font-family: 'Roboto', sans-serif;
    font-size: medium;
    text-align: left;
}

.PopupHeader {
    background-color: white;
    height: 36px;
}

.popupborder {
    border-radius: 10px;
    border: 2px solid #4c81bb;
    background: white;
}

.PopupBody {
    height: 100%;
}

i,
svg {
    /* color: #007bff;*/
    color: #4d92d2
}

svg:hover {
    color: #0056b3 !important;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border-color: transparent !important;
}

.modal-sizing>div {
    max-width: 90vw;
    width: 372px;
    top: 144px;
    z-index: 10002;
    height: 444px;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: 25px !important;
}

a:hover {
    cursor: pointer;
}

.select-search__option.is-selected:hover {
    background: #e7f0f8 !important;
    color: #fff !important;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #e7f0f8 !important;
}

.select-search__option.is-selected {
    background: #5191ce !important;
    /*color: #fff;*/
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #e7f0f8 !important;
}

.alignSpan-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.square {
    display: flex;
    width: 20px;
    height: 20px;
    border: black;
    border-radius: 50%;
}
.circle {
    display: flex;
    width: 20px;
    height: 20px;
    border:1px solid #1e5d9d;
    border-radius: 50%;
}

.success {
    background-color: green;
    color: green;
}

.warning {
    background-color: orange !important;
    color: orange;
}

.failed {
    background-color: red;
    color: red;
}

.na {
    background-color: grey;
    color: grey;
}

.running {
    background-color: yellowgreen;
    color: yellowgreen;
}

.none {
    background-color: black;
    color: black;
}
.loading-cell{
    background-color: #fff;
    color: #1e5d9d; 
}
.header-cell-centered {
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
}


.ag-theme-alpine {
    /* --ag-foreground-color: rgb(11, 48, 116);
    --ag-background-color: rgb(255, 255, 255); */
    --ag-header-foreground-color: rgb(255, 255, 255) !important; 
    --ag-header-background-color: #1e5d9d !important;
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    /* --ag-header-column-resize-handle-color: rgb(253, 253, 253); */

    --ag-font-size: 17px;
    --ag-font-family: monospace;

}
.ag-cell-label-container{
    /* border-right:1px solid rgb(240, 240, 240); */
}

.ag-theme-alpine .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon {
    color: white;
}

/* Custom toop tip styles */
.custom-tooltip {
    position: absolute;
    width: 100px !important;
    height: 90px !important;
    border: 1px solid cornflowerblue;
    background-color: rgb(30 93 157);
    color: rgb(255, 255, 255);
    overflow: hidden;
    pointer-events: none;
    transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
    font-size: small;    
}

.input-icons svg {
    position: absolute;
    height: 28px !important;
    width: 28px !important;
    margin-top: 7px;
    padding-left: 10px;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
   
}
.input-field {
    width: 100%; 
    padding: 10px;
    text-align: center;
}
.input-vac-login{
    border: 1px solid #1e1c1c;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: 2px 2px 3px #666;
    font-size: 20px;
    padding: 4px 7px;
    outline: 0;
    -webkit-appearance: none;
    border-color: #007bff;
}


.input-field input.rounded {
	border: 1px solid #ccc;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-moz-box-shadow: 2px 2px 3px #666;
	-webkit-box-shadow: 2px 2px 3px #666;
	box-shadow: 2px 2px 3px #666;
	font-size: 20px;
	padding: 4px 7px;
	outline: 0;
	-webkit-appearance: none;
}
.input-field input.rounded:focus {
	border-color: #339933;
}
.input-field-text-error input {
   border:1px solid rgb(245, 28, 28) !important
}
.input-field-text-error svg {
    color: red;
 }
 .loginboxouter{
    flex-basis: 0;
    flex-grow: 0.5;
    max-width: 100%;
 }

 .login.header{
    /* align-items: center;
    align-content: center; */
    content: url('../images/logo/logoheader.png') no-repeat fixed;
    position: absolute;
    height: 40px;
    background-color: #fff;
}


/*custom check box styles*/
.customCheckBox  input[type='checkbox'] {
    display:none;
}
 .customCheckBox   input[type='checkbox']+ label:before {
    content: "\f205";
	font-size:1.5rem;
	cursor:pointer;
 
 }
 .customCheckBox  input[type='checkbox']:checked + label:before {
     font-weight:600;
 
 }
 .custom-checkbox input[type="checkbox"],
            .custom-checkbox .checked {
            display: none;
        }
 
        .custom-checkbox input[type="checkbox"]:checked ~ .checked
        {
            display: inline-block;
        }
 
        .custom-checkbox input[type="checkbox"]:checked ~ .unchecked
        {
            display: none;
        }
/*ends*/
.layout-header-text{
    font-size: x-large;
    font-weight: 700;
    border-bottom: #1e5d9d 3px solid;
    color:#15497e;
}
.layout-body-content{
    padding-top: 10px;
}
.prod-p-card svg {
    background-color: #fff;
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding: 11px 0;
    font-size: 18px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-box-shadow: 0 8px 15px rgb(62 57 107 / 20%);
    box-shadow: 0 8px 15px rgb(62 57 107 / 20%);
}

.link-grid{
    cursor: pointer;
    color: cornflowerblue;
    text-decoration: underline;
}
/* .ag-theme-alpine .ag-icon-menu::before{
    color: #fff !important;
} */
.ag-theme-balham .ag-icon-desc,
.ag-theme-balham .ag-icon-asc,
.ag-theme-balham .ag-icon-menu
{
    font-family: "Font Awesome 5 Free";
    /* FontAwesome uses font-weight bold */
    font-weight: bold;
    color: #fff !important;
}

.ag-theme-balham .ag-icon-desc::before
{
    content: '\f063';
    color: white;
    
}

.ag-theme-balham .ag-icon-asc::before
{
    content: '\f062';
    color: white;
}

.ag-theme-balham .ag-icon-menu::before
{
    content: '\f0c9';
    color: white !important;
}

.ag-theme-alpine .ag-ltr input[class^=ag-]:not([type]), .ag-theme-alpine .ag-ltr input[class^=ag-][type=text], .ag-theme-alpine .ag-ltr input[class^=ag-][type=number], .ag-theme-alpine .ag-ltr input[class^=ag-][type=tel], .ag-theme-alpine .ag-ltr input[class^=ag-][type=date], .ag-theme-alpine .ag-ltr input[class^=ag-][type=datetime-local], .ag-theme-alpine .ag-ltr textarea[class^=ag-]{
    color:#000;
}

.m-r-1{
    margin-right: 1rem;
}
.m-r-2{
    margin-right: 2rem;
}
.m-r-3{
    margin-right: 3rem;
}
.m-r-4{
    margin-right: 4rem;
}
.cursor{
    cursor: pointer;
}
.cursor:hover{
    -webkit-animation:zoom-in-out 1s linear 0s 1 normal;
    -moz-animation:zoom-in-out 1s linear 0s 1 normal;
    -ms-animation:zoom-in-out 1s linear 0s 1 normal;
    animation:zoom-in-out 1s linear 0s 1 normal;
}

.angy-animate{
    -webkit-animation:zoom-in-out 1s linear 0s 1 normal;
    -moz-animation:zoom-in-out 1s linear 0s 1 normal;
    -ms-animation:zoom-in-out 1s linear 0s 1 normal;
    animation:zoom-in-out 1s linear 0s 1 normal;
    }
    
    @-webkit-keyframes zoom-in-out {
    0%{ -webkit-transform: scale(1); transform: scale(1); }
    50%{ -webkit-transform: scale(1.2); transform: scale(1.2); }
    100%{ -webkit-transform: scale(1); transform: scale(1); }
    }
    
    @keyframes zoom-in-out {
    0%{ -ms-transform: scale(1); transform: scale(1); }
    50%{ -ms-transform: scale(1.2); transform: scale(1.2); }
    100%{ -ms-transform: scale(1); transform: scale(1); }
    }


.company-details{
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
}
.company-headers{
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #15497e;
}
.company-headers div{
    background-color: #15497e;
    color: #fff;
    font-weight: bolder;
}

/*custom tooltip*/
.customTooltip {
    position: relative;
    display: inline-block;
  }
  
  .customTooltip .customTooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #1e5d9d;
    color: #fff !important;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
  }
  
  .customTooltip .customTooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #1e5d9d transparent;
  }
  
  .customTooltip:hover .customTooltiptext {
    visibility: visible;
  }
  .tooltip-chart{    
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    flex-wrap: nowrap;
    background-color: #15497e;
    color:#fff;
    flex-direction: column;
    width: 100px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #1e5d9d transparent;
  }
  .tooltip-chart span {
    padding: 5px;
  }

  .company-server-status{
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;    
  }
  .company-server-status div i{
    font-size: 120px !important;
  }
  .company-server-status div svg{
    font-size: 120px !important;
  }
  .company-server-status div span{
    color: #fff;    
    width: -webkit-fill-available;
    display: block;
  }
  .bg-c-green {
    background: -webkit-gradient(linear,left top,right top,from(#0ac282),to(#0df3a3));
    background: linear-gradient(to right,#0ac282,#0df3a3);
}
.company-server-status div{
    display: flexbox;
}
.max-width-card{
    max-width: 70%;
    padding: 7px 20px;
}
.success{
 
    border:1px solid #0ac282;
 
}
.success span{    
    /* display: none;
    color: #0ac282;
    font-weight: bold; */
}
.warning-company{
    border:1px solid #b36f0a;
    background-color: #fff;
    color: #b36f0a;
}
.warning-company span{
    color:#b36f0a !important;
    font-weight: bold;
}
.no-found{
    border:1px solid rgb(122, 5, 5);
}
.no-found span{
    font-weight: bold;
    color:rgb(122, 5, 5) !important;    
}
.success-icon i{
    color:#0ac282;
}
.success-icon i{
    color:#0ac282;
}
.warning-icon i{
    color:#b36f0a;
}
.warning-icon:hover{
    color:#b36f0a !important;
}
.error-icon i{
    color:rgb(122, 5, 5);
}
.error-icon i:hover{
    color:rgb(122, 5, 5) !important;
}
.success-icon svg{
    color:#0ac282;
}
.success-icon svg:hover{
    color:#0ac282 !important;
}
.warning-icon svg{
    color:#b36f0a;
}
.warning-icon svg:hover{
    color:#b36f0a !important;
}
.wh-20 svg{
    width: 20px !important;
    height: 20px !important;
}
.error-icon svg{
    color:rgb(122, 5, 5);
}
.error-icon svg:hover{
    color:rgb(122, 5, 5) !important;
}
.error-icon{
    padding-bottom: 20px;
}
.warning-icon{
    padding-bottom: 20px;
}
.management-status{
    padding-top: 50px;
}
.management-status>span{    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: x-large;
    color: #15497e;
    font-weight: 600;
}
.black{
    color:#000;
    background-color: rgb(245 241 241);
}
.rowBlue{
    background-color: rgb(21, 73, 126) !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
}

.item-center{
    text-align: center;
    align-items: center;
}

.pagination-ag-grid{
    border: solid 1px;
    border-color: var(--ag-border-color, #babfc7);
    box-sizing: border-box;
    outline: none;
}
.displayflex{
    display: flex;
    align-items: center;
}
.btn-space{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
.users-password{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.users-password i{
    margin-left: -28px;
    width: 28px;
}
.header-users{
    display: flex;

}
.error-message{
    color: red;
}
.success-message{
    color: green;
}
.warning-message{
color:#15497e;
}
.parent-password{
    display: flex;
}
.child-password{
    width: 100%;
    display: flex;
}
.child-password svg{
    margin-left: -35px;
}
.signupBox{
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    height: auto;
    margin-top: 5%;
}
.form-containter{
    margin-top:20px;
    width:98%;
    border: 1px solid #15497e;
    box-shadow: 4px 3px 8px 1px #969696;
    -webkit-box-shadow: 4px 3px 8px 1px #969696;
    border-radius: 2%;
}
.stats-containter{
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
color: #15497e;
}
.stats-item{
    padding-top: 8px;
    width: 140px;
    height: 100px;
    margin: 10px;
    border: 1px solid #15497e;
    box-shadow: 4px 3px 8px 1px #969696;
    -webkit-box-shadow: 4px 3px 8px 1px #969696;
    border-radius: 10%;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    align-items: center;
}
.countsvalue{
    padding-top: 5px;
    font-size: x-large;
}
@media (max-width: 860px){
    .header-profile{
        display: none !important;
    }
    .header-user-info{
        display: none!important;
    }
}
@media (max-width: 450px){
.sidebar ul.logout{
    bottom: 0 !important;
}
}
.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter svg{
    color:#fff !important;
}
button.Toastify__close-button.Toastify__close-button--colored svg{
color:#fff !important;
}




.loadingx:before {
    position: absolute;
    content: '';
    top: 0%;
    left: 0%;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    border-radius: 0.28571429rem;
    z-index: 100;
  }
  
  .loadingx:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -1.5em 0em 0em -1.5em;
    width: 3em;
    height: 3em;
    -webkit-animation: segment-spin 4s linear infinite;
    animation: segment-spin 4s linear infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #3F51B5 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-top: 5px solid #3F51B5;
    /* border-width: 0.2em; */
    box-shadow: 0px 0px 0px 1px transparent;
    visibility: visible;
    z-index: 101;
  }
  
  @-webkit-keyframes segment-spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes segment-spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .left-function{
    /* position: relative; */
    /* right: 15px;
    float: right; */
    /* top: -40px; */
  }

  .display-header-function{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .cursor{
    cursor: pointer;
  }

  @media print {
    body {
      overflow: visible;
    }
    .sidebar{
        visibility: hidden;
    }
    .contentpanes{
        margin-left: 1px !important;
    }
    .ag-cell-label-container{
        width:35% !important;
    }
  }