.popup-content>div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: flex-start;
}
.popup-content>div>span{
    font-weight: bolder;
}
.popup-content>div>p{
    padding-left: 10px;
}
.modal-content{
    width: 100%;
}
.modal-message{
    max-height: 300px;
    overflow-y: auto;
}